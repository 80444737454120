import axios from "axios";
import { objToFormData, isEmpty } from "~/utils/utils";

const axiosInstance = function (base) {
  const hostname = window.location.hostname;
  let baseURL = base || "/api";
  /* if (["localhost", "dev.jwj.kr"].includes(hostname)) {
    baseURL = base || "/api";
  } else {
    baseURL = `https://jwj.kr${base || "/api"}`;
  } */

  return axios.create({
    baseURL: baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/formed-data",
    },
  });
};

/**
 * [018] 본인 아이디 목록 API
 * @memName : 회원이름
 * @memContact: 휴대폰 번호
 * @certNum : 인증번호
 * @memEmail : 이메일
 *
 */
export const getMyIdListAPI = async (payload) => {
  return await axiosInstance("/api").post(`/user/selectMyId.do`, objToFormData(payload));
};

/**
 * @title [032] 블랙리스트 등록 API
 * @memCd : 블랙리스트에 등록할 회원코드
 * !! 관리자만 사용 가능.(loginStatus의 grade가 manager일 때)
 */
export const insertBlackListAPI = async (payload) => {
  return await axiosInstance("/ajax").post(`/util/insertBlacklistProc.do`, objToFormData(payload));
};

/**
 * @title [036] 사용자 차단/해제하기 API
 * @memCd : 회원코드
 * @delYN : 해제할 경우에만 Y로 보낸다.
 */

export const blockingUserAPI = async ({ memCd, delYN }) => {
  return await axiosInstance("/ajax").post(`/util/blockingUserProc.do`, objToFormData({ memCd, ...(!isEmpty(delYN) && { delYN }) }));
};

/**
 * @title [037] 칼럼,일기,댓글,사용자프로필 신고하기 API
 * @diaryCd : 일기코드
 * @gubun : 구분(t_column, t_post, t_mem)
 * @reason : 신고하기 사유(텍스트)
 */
export const reportPostAPI = async (payload) => {
  try {
    return await axiosInstance("/ajax").post("/diary/informReportPostProc.do", objToFormData(payload));
  } catch (err) {
    throw err;
  }
};

/**
 * 회원정보 수정
 * https://jwj.kr/ajax/usr/updateMemProc.do
 * memCd: 20221227101711222803
loginId: srkks37
gubun: mod
clauseAgreeYn: Y
privacyPolishAgreeYn: Y
parentContactOrg: 
memContactOrg: 01029245310
memEmailOrg: kiseong@kakao.com
chrchCd: 20210310082032008470
minor: N
minorAuth: N
loginPwd: xptmxm123!
loginPwdRe: xptmxm123!
memName: 김기성
nickName: David
memSex: M
memBirthday: 19871211
parentName: 
parentBirthday: 
parentContact: 
parentContactCertNum: 
memContact: 01029245310
memContactCertNum: 
memEmail: kiseong@kakao.com
memEmailCertNum: 
chrchName: 은성제일교회(박기주)
dutyTypeCd: 541
 */
export const updateMemAPI = async (payload) => {
  return await axiosInstance("/ajax").post(`/usr/updateMemProc.do`, objToFormData(payload));
};
