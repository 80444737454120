<template>
  <ClientOnly>
    <NuxtLayout>
      <!-- <NuxtPage :page-key="commonStore.pageKey.app" /> -->
      <NuxtPage />
      <JwConfirmDialog />
    </NuxtLayout>
  </ClientOnly>
</template>

<script setup>
import JwConfirmDialog from "~/components/common/JwConfirmDialog.vue";
//import { getCodeListAPI } from "./store/api/common";
import { useAuthStore } from "~/store/auth";
import { useCommonStore } from "~/store/common";
import { useNativeWebView } from "~/composables/useNativeWebview";
import { useLocaleStore } from "./store/locale";
import { isDev } from "~/utils/utils";
const { isNativeWebView } = useNativeWebView();
const { $origin } = useNuxtApp();

//refreshNuxtData();

//const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();
const commonStore = useCommonStore();
const localeStore = useLocaleStore();
//const { loginStatus: storeLoginStatus = 'pending' } = storeToRefs(authStore);

const storeLoginStatus = computed(() => authStore.loginStatus);

/* definePageMeta({
  keepalive: {
    include: ['Today', 'SectionColumn']
  }
}); */

useHead({
  htmlAttrs: {
    lang: "ko",
  },
  meta: [{ name: "language", content: "Korean" }],
});
definePageMeta({
  lang: "ko",
});

watch(
  () => route,
  (v) => {
    if (route.name == "room-roomCd") {
      document.documentElement.classList.add("scroll-hide");
    } else {
      document.documentElement.classList.remove("scroll-hide");
    }
  },
  { immediate: true, deep: true }
);

const hideView = computed(() => {
  if (["/", "signin"].includes(route.path) && storeLoginStatus.value === "pending") return true;
  else return false;
});

async function getCodeListActor() {
  try {
    //const { data: res = {} } = await getCodeListAPI();
    commonStore.getCodeList();
  } catch (err) {
    console.error(`getCodeList err : `, err);
  }
}

/* function getNativeAppVer() {
  const cookie = useCookie("cookAppsInfo");
  console.log("getNativeAppVer cookie ===> ", cookie);
  //ios앱일 경우 JWJ-iOS-5.00, android앱일 경우 JWJ-Android-5.00 가 아니면 각각의 앱스토어, 구글스토어로 이동시킨다.
} */
function getNativeAppVer() {
  // 투데이 화면으로 옮기도록 한다.

  const cookie = useCookie("cookAppsInfo");

  console.log("getNativeAppVer cookie ===> ", cookie.value);

  //맨 앞의 5만 체크하도록 한다.
  // 가상의 앱 버전 정보 (실제 버전 값에 따라 수정하세요)
  const expectedIOSVersion = "JWJ-iOS-5.00";
  const expectedAndroidVersion = "JWJ-Android-5.00";

  // 앱 스토어 링크 (실제 앱 스토어 URL로 수정하세요)
  const iosStoreLink = "https://apps.apple.com/app/id530081055";
  //const androidStoreLink = "https://play.google.com/store/apps/details?id=com.example.app";
  //const androidStoreLink = "https://play.google.com";
  //const androidStoreLink = `https://play.google.com/store/search?q=%EC%98%88%EC%88%98%EB%8F%99%ED%96%89%EC%9D%BC%EA%B8%B0%EA%B8%B0&c=apps`;
  //play.google.com/store/apps/details?id=com.ntrus.smc.diary&hl=kohttps://play.google.com/store/apps/details?id=com.ntrus.smc.diary&hl=ko

  ㅊhttps: if (cookie.value) {
    // 쿠키 값에서 버전 정보를 추출합니다.
    const appVersion = cookie.value;

    // iOS 앱인지 확인하고 버전 비교
    if (appVersion.startsWith("JWJ-iOS") && appVersion !== expectedIOSVersion) {
      //TODO: 문구를 변경하도록 한다. + 얼럿은 예전에 퍼블리셔가 작업한 것을 이용하도록 한다.
      alert("iOS 앱 버전이 일치하지 않음, 앱스토어로 이동합니다.");
      window.location.href = iosStoreLink;
    }
    // Android 앱인지 확인하고 버전 비교
    else if (appVersion.startsWith("JWJ-Android") && appVersion !== expectedAndroidVersion) {
      alert("Android 앱 버전이 일치하지 않음, 구글스토어로 이동합니다.");
      window.location.href = androidStoreLink;
    }
  } else {
    console.log("쿠키 값이 없습니다.");
  }
}

onBeforeMount(() => {
  if (isDev() && isNativeWebView()) {
    getNativeAppVer();
  }
  //getCodeListActor();
  //authStore.chkLoginStatusActor();
  localeStore.setLangCdListActor();
});

let intervalId;
onMounted(() => {
  //getNativeAppVer();
  if (location.hostname == "localhost") {
    intervalId = setInterval(authStore.chkLoginStatusActor, 60000);
    authStore.chkLoginStatusActor();
  }
  const hostname = location.hostname;
  console.log("location ===> ", location);
  console.log("$origin ===> ", $origin.value);
});

onBeforeUnmount(() => {
  try {
    if (location.hostname == "localhost") {
      clearInterval(intervalId);
    }
  } catch (err) {}
});
</script>

<style lang="scss">
html.scoll-hide {
  overflow-y: hidden;
}
</style>
