import { h } from "vue";
import type { IconSet, IconProps } from "vuetify";
// @ts-ignore
import JwIcon from '@/components/JwIcon.vue';


const svgToIcons: IconSet = {
  component: (props: IconProps) => {
    return h(JwIcon, {
      icon: props.icon as string,
    });
  },
};

export { svgToIcons };