import { memInfoDuplIdAPI } from "./api/common";
import {
  loginAPI,
  logoutAPI,
  sendCertSMSAPI,
  sendCertNumAPI,
  confirmCertNumAPI,
  chkLoginStatusAPI,
  userPwResetByEmailAPI,
  changePwAPI,
  checkIdDuplAPI,
  joinAPI,
  selectChurchAPI,
} from "~/store/api/login";

import { updateMemAPI } from "./api/member";

export const useAuthStore = defineStore("auth", () => {
  let findModalToggle = ref(false);

  const userInfo = reactive({
    id: "",
    loginId: "",
    badgeCnt: "",
    memCd: "",
    profileId: "",
    grade: "",
    profileImg: "",
  });
  let loginStatus = ref("pending");

  const findChurch = reactive({
    list: [],
  });

  const findAccount = reactive({
    dialogOpen: false,
    screenStep: 1,
    resultSeq: "FIND_ID_BY_PHONE", // 'FIND_ID_BY_PHONE' | 'FIND_ID_BY_EMAIL' | 'FIND_PASSWORD'
    findTarget: "ID", // 'ID' | 'PASSWORD'
    userId: "",
    userName: "",
    howFindAccount: "PHONE", // 'PHONE' | 'EMAIL'
    userPhone: "",
    userEmail: "",
    userPassword: "",
    userPasswordVerify: "",
    userDateBirth: "",
    otpNum: "",
  });

  const findPw = reactive({
    modalOpen: false,
  });
  const findId = reactive({
    modalOpen: false,
  });

  async function setFindModal(boolean) {
    findModalToggle = boolean;
    return findModalToggle;
  }

  async function initFindAccount() {
    findAccount.screenStep = 1;
    findAccount.resultSeq = "FIND_ID_BY_PHNE";
    findAccount.findTarget = "ID";
    findAccount.userId = "";
    findAccount.userName = "";
    findAccount.userPhone = "";
    findAccount.userEmail = "";
    findAccount.howFindAccount = "PHONE";
    findAccount.userPassword = "";
    findAccount.userPasswordVerify = "";
    findAccount.userDateBirth = "";
    findAccount.otpNum = "";
    return true;
  }

  async function openFindAccount(target) {
    initFindAccount();
    findAccount.findTarget = target;
    findAccount.dialogOpen = true;
    return true;
  }

  async function memInfoDuplIdActor(data) {
    const { data: res = {} } = await memInfoDuplIdAPI(data);
    return res;
  }

  async function loginActor(payload) {
    try {
      const { statusText, data: res = {} } = await loginAPI(payload);
      if (statusText !== "OK") throw Error;

      //console.log("loginActor res : ", res);

      return res;
    } catch (err) {
      console.error(`loginActor err : `, err);
    }
  }

  async function sendCertSMSActor(payload) {
    try {
      const { statusText, data: res = {} } = await sendCertSMSAPI(payload);
      if (statusText !== "OK") throw Error;

      return res;
    } catch (err) {
      console.error(`sendCertNumActor err : `, err);
    }
  }

  /**
   * 인증번호 유효성 체크 API
   * @param {*} payload
   * @returns
   */
  async function confirmCertNumActor(payload) {
    try {
      const { statusText, data: res = {} } = await confirmCertNumAPI(payload);
      if (statusText !== "OK") throw Error;
      return res.confirm;
    } catch (err) {
      console.error(`confirmCertNumActor err : `, err);
    }
  }

  async function saveIdStorage(id) {
    userInfo.id = id;
    //localStorage.setItem('', id);
    const jwj_token = useCookie("jwj_token");
    jwj_token.value = id;
    return id;
  }

  function setUserInfo({ result, user }) {
    if (result === true) {
      //Object.keys(user).forEach((itm) => (userInfo[itm] = user[itm]));
      Object.keys(user).forEach((itm) => {
        userInfo[itm] = user[itm];
      });
      if (userInfo.profileId) userInfo.profileImg = `https://jwj.kr/upload/member/${userInfo.profileId}`;
      //console.log("userInfo.profileId ::: ", userInfo.profileId);
    } else {
      Object.keys(user).forEach((itm) => (userInfo[itm] = ""));
    }

    //console.log("setUserInfo userInfo : ", userInfo);
  }

  async function chkLoginStatusActor(from) {
    try {
      //
      loginStatus.value = "pending";
      const { statusText, data: res = {} } = await chkLoginStatusAPI();
      if (statusText !== "OK" || res?.result === undefined) throw Error;
      loginStatus.value = res.result;

      if (res.result === true) setUserInfo(res);

      return res.result;
    } catch (err) {
      console.error(`chkLoginStatusActor err : `, err);
      loginStatus.value = false;
    }
  }

  async function sendCertNumActor(payload) {
    try {
      const { statusText, data: res = {} } = await sendCertNumAPI(payload);
      if (statusText !== "OK") throw Error;
      return res;
    } catch (error) {
      console.error(`sendCertNumActor err : `, err);
    }
  }

  async function userPwResetByEmailActor(payload) {
    try {
      const { statusText, data: res = {} } = await userPwResetByEmailAPI(payload);
      if (statusText !== "OK") throw Error;
      return res;
    } catch (err) {
      console.error(`userPwResetByEmailActor err : `, err);
    }
  }

  async function logoutActor() {
    try {
      const { statusText = "", data: res = {} } = await logoutAPI();
      if (statusText != "OK" || res.result != 1) throw Error("logoutActor error");
      // userInfo 초기화
      for (const key in userInfo) {
        userInfo[key] = "";
      }
      return res.result;
    } catch (err) {
      console.error(`logoutActor err : `, err);
    }
  }

  async function changePwActor(payload) {
    const res = await changePwAPI(payload);
    return res;
  }

  async function checkIdDuplActor(payload) {
    const res = await checkIdDuplAPI(payload);
    return res;
  }
  async function joinActor(payload) {
    return await joinAPI(payload);
  }

  /**
   *
   * @param {memName} 이름
   * @param {memEmail} 이메일
   * @param {memBirthday} 생년월일
   * @param {plusInfo} findId
   */
  async function findIdByEmailActor(payload) {
    return await userPwResetByEmailAPI(payload);
  }

  /**
   * @param {churchName} 교회이름, string
   */
  async function selectChurchActor(chrchName) {
    const { data: res = {} } = await selectChurchAPI({ chrchName });
    //findChurch.list = res.chrchList;
    findChurch.list = res.chrchList.map((itm) => ({
      ...itm,
      name: itm.grpName || itm.chrchName,
      address: itm.belongCity || `${itm.state} ${itm.city}`,
      leader: itm.ldrName || itm.chrgPastorName,
      grpCd: itm.grpCd,
    }));
    return res;
  }

  async function updateMemActor(payload) {
    return await updateMemAPI(payload);
  }

  return {
    findPw,
    findId,
    findModalToggle,
    findAccount,
    loginStatus,
    userInfo,
    findChurch,
    setFindModal,
    initFindAccount,
    openFindAccount,
    memInfoDuplIdActor,
    loginActor,
    logoutActor,
    sendCertSMSActor,
    confirmCertNumActor,
    saveIdStorage,
    chkLoginStatusActor,
    sendCertNumActor,
    userPwResetByEmailAPI,
    changePwActor,
    checkIdDuplActor,
    joinActor,
    findIdByEmailActor,
    selectChurchActor,
    updateMemActor,
  };
});
