import { useAuthStore } from '~/store/auth';
import { storeToRefs } from 'pinia';

export default defineNuxtPlugin(() => {
  /* if (process.client) {
    const authStore = useAuthStore();

    addRouteMiddleware(
      'global-middleware',
      async (to, from) => {
        //
        const authStore = useAuthStore();
        const { loginStatus } = storeToRefs(authStore);

        // Await for the promise to resolve before moving on.
        //await authStore.chkLoginStatusActor();
        authStore.chkLoginStatusActor().then(() => {
          const loginCookie = useCookie('jwj_token');
          //
          if (['/', '/signin'].includes(to.path) && loginStatus.value === true) {
            //
            //setPageLayout('default');
            return navigateTo('/today', { replace: true });

            //return '/today';
          }

          if (to.path === '/') {
            return navigateTo('/signin');
          }
        });
      },
      { global: true }
    );
  } */
});
