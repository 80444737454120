export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive("max-input-value", {
    mounted(el, binding, vnode) {
      const maxValue = binding.value;

      el.addEventListener("input", () => {
        let newValue = el.value ? el.value.replace(/[^0-9]/g, "") : "";
        if (parseInt(newValue, 10) > maxValue) {
          newValue = maxValue;
        }

        // v-model 업데이트
        if (vnode.props && typeof vnode.props["onUpdate:modelValue"] === "function") {
          vnode.props["onUpdate:modelValue"](newValue);
        } else {
          el.value = newValue;
        }
      });
    },
  });
});
