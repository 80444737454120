import axios from "axios";
import { objToFormData } from "~/utils/utils";

// https://jwj.kr/common/code/commonCodeList.do

const axiosInstance = function (base) {
  const hostname = window.location.hostname;
  const config = useRuntimeConfig();
  const apiBaseUrl = config.public.API_BASE_URL;
  //console.log("in login.js axiosInstance apiBaseUrl : ", apiBaseUrl);
  let baseURL = base || "/api";

  /* if (["localhost", "dev.jwj.kr"].includes(hostname)) {
    baseURL = base || "/api";
  } else {
    baseURL = `https://jwj.kr${base || "/api"}`;
  } */

  return axios.create({
    baseURL: baseURL,
    timeout: 50000,
    withCredentials: true,
    headers: {
      Accept: "*/*",
      "Content-Type": "multipart/form-data",
      //'Content-Type': 'multipart/formed-data'
    },
  });
};

/* export const getDailyListAPI = async () => {
  try {
    return await axiosInstance().post(`/selectMomentList.do`);
  } catch (err) {
    console.error(`getDailyListAPI err : `, err);
    throw err;
  }
}; */

/* const postActor = async ({ baseUrl, url, }) => {
  try {
    return await axiosInstance(baseUrl || null).post(url, objToFormData());
  } catch (err) {
    throw err;
  }
}; */

/**
 * @title [010] 로그인 실행 API
 * @loginId : 필수
 * @loginPwd : 필수
 * @lang : 비필수, 언어코드
 * @autoLoginYn : 필수
 * @snsCode
 * @redirectURL
 */
export const loginAPI = async (payload) => {
  return await axiosInstance().post(`/login/loginProc.do`, objToFormData(payload));
};

/**
 * @title [011] 로그인 상태 확인 API
 */
export const chkLoginStatusAPI = async () => {
  return await axiosInstance().get(`/login/loginStatus.do`);
};

/**
 * [012] 아이디 중복확인
 * @loginId
 */
export const checkIdDuplAPI = async (payload) => {
  return await axiosInstance().get(`/user/checkIdDuplicate.do`, { params: { ...payload } });
};

/**
 * @title [013] 인증번호 휴대폰/이메일 발송 API
 * @memContact :휴대폰 번호
 * @memEmail : 이메일
 * @m_birthDay : 생년월일
 */
export const sendCertNumAPI = async (payload) => {
  try {
    return await axiosInstance("/common").post(`/usr/sendCertNum.do`, objToFormData(payload));
  } catch (err) {
    console.error(`sendCertNumAPI err : `, err);
    throw err;
  }
};

/**
 * @title [014] 인증번호 유효성 체크 API
 * @memContact : 휴대폰 번호
 * @memEmail : 이메일
 * @memName : 이름
 * @certNum : 인증번호
 */
export const confirmCertNumAPI = async (payload) => {
  try {
    return await axiosInstance("/common").post(`/usr/confirmCertNum.do`, objToFormData(payload));
  } catch (err) {
    console.error(`confirmCertNumAPI err : `, err);
    throw err;
  }
};

/**
 * @title [015] 아이디/비밀번호 휴대폰 인증번호 발송 API
 * @loginId : 아이디
 * @memName : 이름
 * @memContact : 휴대폰번호
 * @gubun : 구분, 아이디-> findId / 비밀번호-> findPwd
 */
export const sendCertSMSAPI = async (payload) => {
  try {
    return await axiosInstance("/common").post(`/certSMSsend.do`, objToFormData(payload));
  } catch (err) {
    console.error(`sendCertSMSAPI err : `, err);
    throw err;
  }
};

/**
 * @title [016] 아이디/비밀번호 이메일 조회 API
 * @loginId : 아이디
 * @memName : 이름
 * @memContact : 휴대폰번호
 * @gubun : 구분(아이디 - findId, 비밀번호 - findPwd)
 */
export const userPwResetByEmailAPI = async (payload) => {
  return await axiosInstance("/login").post("/selectUserInfoFind.do", objToFormData(payload));
};

/**
 * 인증번호 유효성을 체크해서 아이디 목록을 조회
 */
export const findIdListAPI = async (payload) => {
  return await axiosInstance("/common").post("/findIDListKR.do", objToFormData(payload));
};

/**
 * [112] 로그아웃 API
 */
export const logoutAPI = async () => {
  return axiosInstance("/api").get(`/logout.do`);
};

/**
 * [019] 비밀번호 변경 API
 * @loginId
 * @gubun : findPwd
 * @memContact
 * @loginPwd : 변경할 비밀번호
 * @loginPwdRe
 * @certNum : SMS 인증번호
 */
export const changePwAPI = async (payload) => {
  return await axiosInstance("/common").post(`/setPassword.do`, objToFormData(payload));
};

/**
 * [020] 회원가입 API
 *  @clauseAgreeYn : 약관동의
 *  @privacyPolishAgreeYn : 개인정보 수집 동의
 *  @memTypeCd : 회원유형코드 COMMONCD004000000003
 *  @chrchCd : 교회 코드
 *  @lang : 언어코드
 *  @cert_result : 실명인증결과
 *  @gubun : 'join'
 *  @joinDate : 가입일시(YYYYMMDDHHmmss)
 *  @certMet
 *  @minor : 18세 이하 여부
 *  @minorAuth : 14세 미만 여부
 */
export const joinAPI = async (payload) => {
  return await axiosInstance("/common").post(`/usr/insertUserProc.do`, objToFormData(payload));
};

/**
 * 교회 검색
 * @param {chrchName} 교회이름, string => 철자에 u가 없다. 조심.
 */
export const selectChurchAPI = async (payload) => {
  return await axiosInstance("/login").post(`/selectChrchInfo.do`, objToFormData(payload));
};
