import { useI18n } from "vue-i18n";
import dayjs from "dayjs";
import "dayjs/locale/ko"; // 한국어 로케일
import "dayjs/locale/en"; // 영어 로케일
import "dayjs/locale/zh-cn"; // 중국어(간체) 로케일
import "dayjs/locale/zh-tw.js"; // 중국어(번체) 로케일
import "dayjs/locale/id.js"; // 인도네시아어 로케일
import { getCodeListAPI } from "./api/common";

export const useLocaleStore = defineStore("locale", () => {
  const { t, locale } = useI18n();

  watch(
    () => locale.value,
    (newVal) => {
      console.log("in store locale.value ==> ", newVal);
    },
    { deep: true, immediate: true }
  );

  /* 
  codeList =>
   {
    commonCD: "COMMONCD001000000000",,
    commonCDLevel:"2",
    langMssgCd:"dwj.comcd.001.001",
    langMssgNm:"한국어",
    sortNm:"1",
    upperCommonCd:"COMMONCD001000000000",
   }
  */

  const state = reactive({
    lang: locale.value,
    langCd: "",
    codeList: [],
  });
  const langCd = computed(() => {
    if (!state?.codeList?.length == 0) return "COMMONCD001000000000";
    return state.codeList.find((itm) => itm.langMssgNm === locale.value)?.commonCd;
  });

  const dayjsLocale = computed(() => dayjs.locale(locale.value || "ko"));

  function dateLangFormatter(dateString) {
    return dayjs(dateString).format(
      locale.value === "ko" ? "YYYY년 M월 DD일" : locale.value === "en" ? "MMMM DD, YYYY" : "YYYY-MM-DD" // 기본 포맷
    );
  }

  function labelToCode(key) {
    const match = {
      말씀읽기: "diary.monthlist.card.read",
      기도: "diary.monthlist.card.pray",
      묵상: "diary.monthlist.card.qt",
      예수님바라보기: "diary.monthlist.card.jesus",
      순종하기: "diary.monthlist.card.obey",
    };
    return match[key];
  }

  function tagToCode(tagName) {
    const match = {
      직장: "diary.card.desc.tag.company",
      가족: "diary.card.desc.tag.family",
      재정: "diary.card.desc.tag.finance",
      신앙: "diary.card.desc.tag.faith",
      비전: "diary.card.desc.tag.vision",
      교회: "diary.card.desc.tag.church",
      일상: "diary.card.desc.tag.daily",
      말씀: "diary.card.desc.tag.bible",
      나눔: "diary.card.desc.tag.sharing",
      이웃: "diary.card.desc.tag.neighbor",
    };
    return match[tagName];
  }

  function setLang(lang) {
    state.lang = lang;
  }

  function langNameMatcher(lang) {
    const match = {
      ko: "한국어",
      en: "English",
      tw: "中文(台灣)",
      cn: "中文(中国)",
      id: "Bahasa lain",
    };
    return match[lang];
  }

  async function setLangCdListActor() {
    try {
      const { data: res = {} } = await getCodeListAPI();
      if (!res || !res?.codeList) throw Error;
      state.codeList = res.codeList.map((itm) => ({ ...itm, langValue: langNameMatcher(itm.langMssgNm) }));
      console.log("state.codeList : ", state.codeList);
      return state.codeList;
    } catch (err) {
      console.error(`setLangCdListActor err : `, err);
    }
  }

  return {
    state,
    dayjsLocale,
    dateLangFormatter,
    labelToCode,
    tagToCode,
    setLang,
    setLangCdListActor,
    langCd,
  };
});
