// plugins/platform.ts
export default defineNuxtPlugin((nuxtApp) => {
  const userAgent = process.client ? window.navigator.userAgent : "";

  const isIOS = /iPhone|iPad|iPod/i.test(userAgent);
  const isAndroid = /Android/i.test(userAgent);
  const isWebView = /WebView|wv/.test(userAgent);

  // Nuxt 앱에 platform 객체를 주입
  return {
    provide: {
      platform: {
        isIOS,
        isAndroid,
        isWebView,
      },
    },
  };
});
