import { createVuetify } from 'vuetify';
import { svgToIcons } from '~~/utils/svgToIcon';

export default defineNuxtPlugin(app => {
  const vuetify = createVuetify({
    ssr: true,
    // defaults,
    defaults: {
      // 이상하게 어느순간 utils 폴더가 자동 참조(defaults, themes.dark, iconOption)가 안됨...
      global: {
        ripple: false
      },
      VAppBar: {
        height: 55,
        elevation: 0
      },
      VToolbar: {
        height: 55
      },
      VBtn: {
        class: 'jw-btn',
        ripple: false,
        elevation: 0
      },
      VBtnToggle: {
        class: 'jw-btn-toggle',
        elevation: 0
      },
      VCheckbox: {
        density: 'compact',
        class: 'jw-checkbox'
      },
      VChip: {
        class: 'jw-chip'
      },
      VSwitch: {
        class: 'jw-switch'
      },
      VTextField: {
        color: 'primary',
        variant: 'outlined',
        density: 'comfortable',
        class: 'jw-textfield'
      },
      VSelect: {
        class: 'jw-select',
        menuIcon: 'jwIcons:svg/chevron-down'
      },
      VTextarea: {
        class: 'jw-textarea'
      },
      VBottomNavigation: {
        class: 'jw-bottom-nav',
        height: 57
      },
      VTabs: {
        class: 'jw-tabs'
      },
      VTab: {
        ripple: false,
        class: 'jw-tab'
      }
    },
    // add theme
    theme: {
      defaultTheme: LIGHT_THEME,
      themes: {
        light
        // dark
      }
    },
    display: {
      mobileBreakpoint: 'sm',
      thresholds: {
        xs: 0,
        sm: 340, //391
        md: 540,
        lg: 800,
        xl: 1000 //1280
      }
    },
    // Add the custom iconset
    icons: {
      defaultSet: 'custom',
      // aliases,
      sets: {
        custom,
        jwIcons: svgToIcons
      }
    }
  });

  app.vueApp.use(vuetify);
});
