// plugins/origin.client.ts

export default defineNuxtPlugin(() => {
  const computedOrigin = computed(() => {
    const hostname = location.hostname;
    if (["localhost", "dev.jwj.kr"].includes(hostname)) {
      return "https://dev.jwj.kr";
    }
    return "https://jwj.kr";
  });

  return {
    provide: {
      origin: computedOrigin,
    },
  };
});
