import { ThemeDefinition } from 'vuetify';

// String that represents the name of the theme I am using
export const LIGHT_THEME = 'light';
// Light mode theme
export const light: ThemeDefinition = {
  dark: false,
  colors: {
    background: '#FFFCF8',
    surface: '#FFFCF8',
    primary: '#2DA562',
    secondary: '#1B6BC9',
    error: twColors.red[500],
    info: twColors.blue[500],
    success: twColors.emerald[500],
    warning: '#FF7A00',
    input: '#FFFFFF',
    //회원가입의 중복확인 버튼이 안나와서 보니 이게 빠져있었음
    jwGrey: '#BFBFBF',
    jwGrey2: '#F2F2F2',
    jwGrey3: '#C4C4C4',
    jwGreen: '#2DA562'
  }
};

// String that represents the name of the dark theme I am using
export const DARK_THEME = 'dark';
// Dark mode theme
export const dark: ThemeDefinition = {
  dark: true,
  colors: {
    background: twColors.slate[950],
    surface: twColors.slate[900],
    primary: '#2DA562',
    secondary: '#1B6BC9',
    error: twColors.red[500],
    info: twColors.blue[500],
    success: twColors.emerald[500],
    warning: '#FF7A00',
    input: '#FFFFFF',
    //회원가입의 중복확인 버튼이 안나와서 보니 이게 빠져있었음
    jwGrey: '#BFBFBF',
    jwGrey2: '#F2F2F2',
    jwGrey3: '#C4C4C4',
    jwGreen: '#2DA562'
  }
};
