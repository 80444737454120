<template>
  <v-dialog v-model="computedModalOpen" persistent :max-width="isMobile ? '80vw' : '270px'" max-height="500px" scrollable>
    <v-card class="jw-dialog jw-dialog--confirm">
      <!-- 다이알로그 헤더영역 -->
      <v-toolbar class="jw-dialog__header" height="44" v-if="confirmDialog.title">
        <v-toolbar-title class="text-center ml-0">{{ confirmDialog.title }}</v-toolbar-title>
        <v-toolbar-items>
          <!-- 닫기버튼 -->
          <!-- <v-btn icon="jwIcons:svg/close" @click="modalOpen = false"></v-btn> -->
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="jw-dialog__body text-center" v-if="confirmDialog.text">
        <!-- 차단하실 경우 이 사용자의 모든 글은 숨겨집니다. -->
        <!-- <div style="height: 500px; border: 1px solid red">아주아주 긴 영역</div> -->
        <div v-html="confirmDialog.text" />
      </v-card-text>
      <v-card-actions class="jw-dialog__footer justify-space-between">
        <VBtn variant="text" class="flex-1-1 px-4" @click="onClickNegative" v-if="confirmDialog.negative.name">
          {{ confirmDialog.negative.name }}</VBtn
        >
        <VBtn variant="text" class="flex-1-1 px-4" @click="onClickPositive">{{ confirmDialog.positive.name || "확인" }}</VBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useCommonStore } from "~/store/common";
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";

const { smAndDown } = useDisplay();
const isMobile = computed(() => smAndDown.value);

const commonStore = useCommonStore();
const { confirmDialog } = storeToRefs(commonStore);

const computedModalOpen = computed({
  get: () => confirmDialog.value.open,
  set: (value) => commonStore.setConfirmDialog({ open: value }),
});

watch(
  () => confirmDialog.value,
  () => {},
  {
    deep: true,
    immediate: true,
  }
);

const modalOpen = computed(() => {
  return confirmDialog.value.open;
});

function closeDialog() {
  commonStore.setConfirmDialog({ open: false });
}

async function onClickPositive() {
  if (!isEmpty(confirmDialog.value.positive.actor)) confirmDialog.value.positive.actor();
  closeDialog();
}

async function onClickNegative() {
  if (!isEmpty(confirmDialog.value.negative.actor)) confirmDialog.value.negative.actor();
  closeDialog();
}
</script>

<style lang="scss" scoped></style>
