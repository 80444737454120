import { createI18n } from 'vue-i18n';
import ko from '@/locales/ko/default.json';
import en from '@/locales/en/default.json';
import cn from '@/locales/cn/default.json';
import tw from '@/locales/tw/default.json';
import id from '@/locales/id/default.json';

export default defineNuxtPlugin(({ vueApp }) => {
  const i18n = createI18n({
    legacy: false,
    globalInjection: true,
    locale: 'ko',
    fallbackLocale: 'ko',
    messages: {
      ko,
      en,
      cn,
      tw,
      id
    }
  });

  vueApp.use(i18n);
});
