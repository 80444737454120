export function useNativeWebView() {
  const isNativeWebView = (): boolean => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS 웹뷰 감지
    const isIosWebView =
      /iPhone|iPod|iPad/i.test(userAgent) && !(window as any).MSStream && userAgent.includes("AppleWebKit") && !userAgent.includes("Safari");

    // Android 웹뷰 감지
    const isAndroidWebView = /Android/i.test(userAgent) && userAgent.includes("wv");

    return isIosWebView || isAndroidWebView;
  };

  return {
    isNativeWebView,
  };
}
